/*
 * @Description: 
 * @Author: Rabbiter
 * @Date: 2023-01-14 20:02:29
 */
module.exports = {
    lintOnSave: false, // 关闭eslint校验
    devServer: {
        host: "localhost",
        port: 9282,
        https: false,
        proxy: "https://hospital.xiugen.cn",
        overlay: { // 关闭eslint校验
            warning: false,
            errors: false
        },
    }
}
//设置代理解决跨域问题