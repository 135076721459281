var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":6}},[_c('el-input',{attrs:{"placeholder":"请输入患者id查询"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[_c('el-button',{staticStyle:{"font-size":"18px"},attrs:{"slot":"append"},on:{"click":_vm.requestOrders},slot:"append"},[_c('i',{staticClass:"iconfont icon-r-find",staticStyle:{"font-size":"22px"}}),_vm._v(" 查询")])],1)],1)],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.orderData,"stripe":"","border":""}},[_c('el-table-column',{attrs:{"prop":"oId","label":"挂号单号","width":"80px"}}),_c('el-table-column',{attrs:{"prop":"dId","label":"本人id","width":"80px"}}),_c('el-table-column',{attrs:{"prop":"pId","label":"患者id","width":"100px"}}),_c('el-table-column',{attrs:{"prop":"oStart","label":"挂号时间","width":"190px"}}),_c('el-table-column',{attrs:{"prop":"oEnd","label":"结束时间","width":"180px"}}),_c('el-table-column',{attrs:{"prop":"oRecord","label":"病因","width":"400px"}}),_c('el-table-column',{attrs:{"prop":"oDrug","label":"药物","width":"180px"}}),_c('el-table-column',{attrs:{"prop":"oCheck","label":"检查项目","width":"180px"}}),_c('el-table-column',{attrs:{"prop":"oTotalPrice","label":"需交费用/元","width":"80px"}}),_c('el-table-column',{attrs:{"prop":"oPriceState","label":"缴费状态","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.oPriceState === 1)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已缴费")]):_vm._e(),(
                            scope.row.oPriceState === 0 &&
                            scope.row.oState === 1
                        )?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("未缴费")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"oState","label":"挂号状态","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                            scope.row.oState === 1 &&
                            scope.row.oPriceState === 1
                        )?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已完成")]):_vm._e(),(
                            scope.row.oState === 0 && scope.row.oState === 0
                        )?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("未完成")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"140","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                            scope.row.oState === 1 &&
                            scope.row.oPriceState === 1
                        )?_c('el-button',{staticStyle:{"font-size":"18px"},attrs:{"type":"warning"},on:{"click":function($event){return _vm.dealClick(scope.row.oId, scope.row.pId)}}},[_c('i',{staticClass:"iconfont icon-r-yes",staticStyle:{"font-size":"22px"}}),_vm._v(" 追诊")]):_vm._e()]}}])})],1),_c('el-pagination',{attrs:{"background":"","layout":"total, sizes, prev, pager, next, jumper","current-page":_vm.pageNumber,"page-size":_vm.size,"page-sizes":[1, 2, 4, 8, 16],"total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }