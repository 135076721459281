<template>
    <div>sdfsdf</div>
</template>
<script>
export default {
    name: "SectionList",
    data() {
        return {
           
        }
    },
    created(){
     
    }
}
</script>