var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.orderData,"stripe":"","border":""}},[_c('el-table-column',{attrs:{"prop":"oId","label":"挂号单号","width":"75px"}}),_c('el-table-column',{attrs:{"prop":"pId","label":"本人id","width":"75px"}}),_c('el-table-column',{attrs:{"prop":"pName","label":"姓名","width":"75px"}}),_c('el-table-column',{attrs:{"prop":"dId","label":"医生id","width":"75px"}}),_c('el-table-column',{attrs:{"prop":"dName","label":"医生姓名","width":"75px"}}),_c('el-table-column',{attrs:{"prop":"oStart","label":"挂号时间","width":"195px"}}),_c('el-table-column',{attrs:{"prop":"oEnd","label":"结束时间","width":"185px"}}),_c('el-table-column',{attrs:{"prop":"oTotalPrice","label":"需交费用/元","width":"80px"}}),_c('el-table-column',{attrs:{"prop":"oPriceState","label":"缴费状态","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.oPriceState === 1)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已缴费")]):_vm._e(),(
                            scope.row.oPriceState === 0 &&
                            scope.row.oState === 1
                        )?_c('el-button',{staticStyle:{"font-size":"14px"},attrs:{"type":"warning","icon":"iconfont icon-r-mark1"},on:{"click":function($event){return _vm.priceClick(scope.row.oId, scope.row.dId)}}},[_vm._v(" 点击缴费")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"oState","label":"挂号状态","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                            scope.row.oState === 1 &&
                            scope.row.oPriceState === 1
                        )?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已完成")]):_vm._e(),(
                            scope.row.oState === 0 && scope.row.oState === 0
                        )?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("未完成")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"报告单"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                            scope.row.oState === 1 &&
                            scope.row.oPriceState === 1
                        )?_c('el-button',{staticStyle:{"font-size":"14px"},attrs:{"type":"success","icon":"iconfont icon-r-find"},on:{"click":function($event){return _vm.seeReport(scope.row.oId)}}},[_vm._v(" 查看")]):_vm._e()]}}])})],1)],1),_c('el-dialog',{attrs:{"title":"用户评价","visible":_vm.starVisible},on:{"update:visible":function($event){_vm.starVisible=$event}}},[_c('div',[_c('h3',[_vm._v(" 请对工号："+_vm._s(_vm.dId)+" 医生："+_vm._s(_vm.dName)+" 进行评价 ")])]),_c('div',[_c('el-rate',{attrs:{"show-text":""},model:{value:(_vm.star),callback:function ($$v) {_vm.star=$$v},expression:"star"}})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"font-size":"18px"},on:{"click":function($event){_vm.starVisible = false}}},[_c('i',{staticClass:"iconfont icon-r-left",staticStyle:{"font-size":"20px"}}),_vm._v(" 取 消")]),_c('el-button',{staticStyle:{"font-size":"18px"},attrs:{"type":"primary"},on:{"click":_vm.starClick}},[_c('i',{staticClass:"iconfont icon-r-yes",staticStyle:{"font-size":"20px"}}),_vm._v(" 确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }